import React from 'react';
import './experience.css';

export class Experience extends React.Component {
    render() {
        return (
            <div className="section">
                <h1>About Us</h1>
                <p>We are a technology company who offer development, testing and deployment implementations to a vast array of global enterprise clients. We have extensive technical and product knowledge, with experience in a vast range of industries to help you develop software or product implementations to help improve your business.</p>
                <ul>
                    <li>Consulting offering solutions and enterprise architecture.</li>
                    <li>Development in Microsoft on premise and cloud technologies.</li>
                    <li>Automation testing for speeding up QA processes.</li>
                    <li>Deployment automation for better Application Lifecycle Management.</li>
                </ul>
            </div>
        )
    }
}