import React from 'react';

export class Integrations extends React.Component {
    render() {
        return (
            <div className="section">
                <h1>Integrations</h1>
                <p>We have built integrations with custom portals, internal systems and cloud components for global high traffic clients. We offer everything from integration architecture design, development or reviews on existing implementations.</p>
            </div>
        )
    }
}