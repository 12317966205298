import React from 'react'
import './footer.css'

export class Footer extends React.Component {
    render() {
        return (
            <footer className="section">
                <p>
                    ASHERKHAN LIMITED is a registered company.
                    Company number 10207781<br />
                    Jubilee House, East Beach, Lytham St. Annes, England, FY8 5FT
                </p>
            </footer>
        )
    }
}