import React from 'react';

export class Products extends React.Component {
    render() {
        return (
            <div className="section">
                <h1>Product Development</h1>
                <p>We are developing our own products due to launch at the end of 2022 and will be excited to release more details shortly. Building enterprise apps and technology is what we are passionate about and doing this for ourselves is the best way for us to develop tools which we can then share with our clients. More details coming soon.</p>
            </div>
        )
    }
}