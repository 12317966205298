import React from 'react';
import './header.css';

export class Header extends React.Component {
    render() {
        return (
            <header className="section">
                <div className="logo">ASHERKHAN LIMITED</div>
            </header>
        )
    }
}