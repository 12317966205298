import React from 'react';

export class ContactUs extends React.Component {
    render() {
        return (
            <div className="section">
                <h1>Contact Us</h1>
                <p>Email us at <a href="mailto:enquiries@asherkhanltd.com">enquiries@asherkhanltd.com</a></p>
            </div>
        )
    }
}