import React from 'react'

export class Crm extends React.Component {
    render() {
        return (
            <div className="section">
                <h1>Customer Relationship Management</h1>
                <p>Specialized in Dynamics 365, we offer customization and development of CRM to help you know your customers better, digitally transform your processes and centralize your internal applications.</p>
            </div>
        )
    }
}