import './App.css';
import { Header } from './features/header/header';
import { Footer } from './features/footer/footer';

import { Crm } from './features/development/crm';
import { Integrations } from './features/development/integrations';
import { Products } from './features/development/products';
import { Experience } from './features/experience/experiece';
import { ContactUs } from './features/contact/contactus';


function App() {
  return (
    <div className="App">
      <Header />
      <Experience />
      <Crm />
      <Integrations />
      <Products />
      <ContactUs />
      <Footer />
    </div>
  );
}

export default App;